import {defineStore} from 'pinia'

const userStore = defineStore('store',{
    state:()=>{
        return{
            token:'34'
        }
    },
    actions:{
        setToken(value){
            this.token = value
        }
    },
    persist:{
        enabled:true
    }
})

export default userStore