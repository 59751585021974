import { createApp } from 'vue'
import router from './router/router'
import Antd from 'ant-design-vue';
import App from './Index.vue'
import 'ant-design-vue/dist/antd.css';
import _ from 'lodash'
import store from './store'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import 'amfe-flexible';
import Prism from 'prismjs';

import './assets/css/font.css'

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});


import request from './plugin/utils/areq'




const app = createApp(App)
app.config.globalProperties.$request = request
app.use(router).use(store).use(_).use(Antd).use(VueMarkdownEditor).mount('#app')
