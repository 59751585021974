<template>
    <div>
        <router-view></router-view>
    </div>
    
  </template>
  
  <script>
  import { getCurrentInstance } from 'vue'
  import userStore from './store/index.js'
  import { useRouter } from 'vue-router'
  export default {
    name: 'App',
    components: {
      
    },
    setup(){
      const userInfo = userStore()
      const router = useRouter()
      function handleLoginStatus(){
        
        if(userInfo.token && userInfo.token !='34'){
          console.log('用户登录了 校验')
        }else{
          console.log('用户没登录 直接调到login')
         // router.push('/login')
        }
        const { proxy } = getCurrentInstance()
        proxy.$request('https://www.fastmock.site/mock/600110b8b734aaacab28a67091a49ac2/v3demo/api/teste', {}, 'get')
        .then(res => {
          console.log(res)
        }).catch( err =>{
          console.log('出了点错误')
          console.log(err)
          
        })
      }
      handleLoginStatus()
      return {handleLoginStatus}
    }
  }
  </script>
  
  <style lang="scss">

  </style>
  