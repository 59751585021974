import http from './request.js'

const request = (url,data,methods) =>{
    if(methods && methods == 'POST'){
        return http.post(url,data)
    }else{
        console.log('来到了这里')
        return http.get(url,data)
    }
    
} 

export default request