import axios from 'axios';

var instance = axios.create({
    baseURL:'http://localhost:8080',
    timeout:2500
});

instance.interceptors.request.use(function(config){
    return config
},function(error){
    console.log(error)
    return Promise.reject(error)
});

instance.interceptors.response.use(function(response){
    return response
},function(error){
    return Promise.reject(error)
})

export default instance