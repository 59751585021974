import {createRouter, createWebHashHistory} from "vue-router";

const routes = [
    {
        path: '/',
        name:'home',
        component:()=>import("../pages/dashboard")
    },
    {
        path:'/aboutSite',
        name:'about',
        meta:{
            title:'about'
        },
        component:()=>import("../pages/aboutSite")
    },{
        path:'/demo',
        name:'demo',
        component:()=>import("../pages/demoParent")
    },{
        path:'/error',
        name:'error',
        component:()=>import("../pages/errorPage")
    },{
        path:'/login',
        name:'login',
        component:()=>import("../pages/loginPage")
    },{
        path:'/blogsList',
        name:'blogsList',
        component:()=>import("../pages/blogsList"),
        children:[
            {
                path:'/blogDetail',
                name:'blogDetail',
                component:()=>import('../pages/aboutSite')
            }
            
        ]
    },{
        path:'/codepage',
        name:'codepage',
        component:()=>import("../pages/codePage")
    },{
        path:'/otherpage',
        name:'otherpage',
        component:()=>import("../pages/otherPage")
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((guard)=>{
    console.log(guard)
    if(!router.hasRoute(guard.name)){
       router.push('/error')
    }
})

export default router;